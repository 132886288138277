import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './FloorPlan.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";
import page1 from "../../assets/FloorPlan/FloorPlan11/page1.jpg";

const FloorPlan6 = () => {
	const menuContents = [
		{ title: "84A", url: "/FloorPlan/84A" },
		{ title: "84B", url: "/FloorPlan/84B" },
		{ title: "84C", url: "/FloorPlan/84C" },
		{ title: "84D", url: "/FloorPlan/84D" },
		{ title: "101A", url: "/FloorPlan/101A" },
		{ title: "101B", url: "/FloorPlan/101B" },
		{ title: "101C", url: "/FloorPlan/101C" },
		{ title: "120A", url: "/FloorPlan/120A" },
		{ title: "120B", url: "/FloorPlan/120B" },
		{ title: "170P", url: "/FloorPlan/170P" },
		{ title: "199P", url: "/FloorPlan/199P" },
		{ title: "240P", url: "/FloorPlan/240P" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>
			


			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="세대안내" />

			<MenuBar contents={menuContents} />

			<img src={page1} className={styles.page1Image} alt="FloorPlan-image-11" />

			<div className={styles.commonBox}>
				<div className={styles.notice}>
					※ 평면도는 소비자의 이해를 돕기 위한 것으로 실 시공 시 다소 변경될 수 있으며, 자세한 내용은 반드시 견본주택에서 확인하시기 바랍니다.
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default FloorPlan6;
