import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './ComplexGuide.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/ComplexGuide/ComplexGuide2/page1.jpg";
import page2 from "../../assets/ComplexGuide/ComplexGuide2/page2.jpg";

const ComplexGuide1 = () => {
  const menuContents = [
    { title: "단지 배치도", url: "/ComplexGuide/intro" },
    { title: "호수 배치도", url: "/ComplexGuide/detailintro" },
    { title: "커뮤니티", url: "/ComplexGuide/community" },
  ];

  const [isScroll, setIsScroll] = useState(false);
  const [selectedComplex, setSelectedComplex] = useState(1); // 기본값을 1로 설정
  const { pathname } = useLocation(); // 현재 경로를 가져옴

  // 페이지 로드 시, 경로에 따라 selectedComplex 값을 설정합니다.
  useEffect(() => {
    if (pathname.includes("1")) {
      setSelectedComplex(1); // 1단지 URL일 때
    } else if (pathname.includes("2")) {
      setSelectedComplex(2); // 2단지 URL일 때
    }
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
  }, [pathname]);

  // 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.container}>
      

      <Header isChanged={isScroll} />
      <FixIcon />

      <Bener title="단지안내"/>

      <MenuBar contents={menuContents} />

      <div className={styles.textBox}>
				<div>기대했던 모든 프리미엄이</div>
				<div>힐스테이트 도안리버파크 2차에서 펼쳐집니다</div>
			</div>

      <div className={styles.complexSelector2}>
        <button 
          onClick={() => setSelectedComplex(1)}
          className={`${styles.button} ${selectedComplex === 1 ? styles['active-1'] : ''}`}
        >
          3단지
        </button>
        <button 
          onClick={() => setSelectedComplex(2)}
          className={`${styles.button} ${selectedComplex === 2 ? styles['active-2'] : ''}`}
        >
          5단지
        </button>
      </div>

      {/* 선택된 단지에 따라 이미지 표시 */}
      {selectedComplex === 1 && (
        <>
          <img src={page1} className={styles.image} alt="ComplexGuide1-image-1" />
      
        </>
      )}

      {selectedComplex === 2 && (
        <>
          <img src={page2} className={styles.image} alt="ComplexGuide2-image-1" />
        
        </>
      )}

      <div className={styles.commonBox}>
        {selectedComplex === 1 && (
          <div>
            <div className={styles.notice}>※ 3단지 배치도 및 관련 정보</div>
          </div>
        )}
        {selectedComplex === 2 && (
          <div>
            <div className={styles.notice}>※ 5단지 배치도 및 관련 정보</div>
          </div>
        )}
        <div className={styles.notice}>※ 상기 단지배치도는 소비자의 이해를 돕기 위한 것으로 실제와 차이가 있을 수 있습니다.</div>
        <div className={styles.notice}>※ 배치도 내 단지 경계선 및 방위표는 CG상의 표현으로 실제와 다소 차이가 있을 수 있으며, 구획선 및 시설물의 위치, 근린생활시설(상가), 규모 등은 측정결과 및 각종 기관의 평가에 따라 실 시공시 다소 변경될 수 있습니다.</div>
        <div className={styles.notice}>※ 지하주차장 입구와 인접한 세대 및 단지내·외 도로(도시계획도로, 비상차로, 소방차전용구역) 등에 인접한 저층부 세대의 경우 차량소음, 자동차 전조등에 의한 환경권, 사생활권이 침해될 수 있습니다.</div>
        <div className={styles.notice}>※ 상기 동·호수표는 제작과정상 오류가 있을 수 있으니, 반드시 홈페이지 또는 견본주택에서 확인하시기 바랍니다.</div>
      </div>

      <Footer />
    </div>
  );
}

export default ComplexGuide1;
