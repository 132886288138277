import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './SalesInfo.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/SalesInfo/SubscriptionGuide/page1.jpg";

const ComplexGuide1 = () => {
	const menuContents = [
		{ title: "인터넷 청약", url: "/SalesInfo/guide" },
		{ title: "체크포인트", url: "/SalesInfo/SubscriptionGuide" },
		{ title: "모집공고안내", url: "/SalesInfo/announcement" },
		{ title: "인지세납부안내", url: "/SalesInfo/stampTax" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>
			

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="모집공고안내" />

			<MenuBar contents={menuContents} />

			<div className={styles.textBox}>
				<div>입주자 모집공고안내를 확인하세요</div>
				<div>소수만 누리는 프리미엄 힐스테이트 도안리버파크 2차</div>
			</div>

			<img className={styles.image2} src={page1} />

			<div className={styles.commonBox}>
				<div className={styles.notice}>
					※ 상기 내용은 편집과정상 오류가 있을 수 있으니 반드시 입주자모집공고를 확인하시기 바랍니다.
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default ComplexGuide1;
