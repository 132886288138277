import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './ComplexGuide.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/ComplexGuide/ComplexGuide3/page1.jpg"

const ComplexGuide3 = () => {
	const menuContents = [
		{ title: "단지 배치도", url: "/ComplexGuide/intro" },
		{ title: "호수 배치도", url: "/ComplexGuide/detailintro" },
		{ title: "커뮤니티", url: "/ComplexGuide/community" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>
			
			

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="단지안내" />

			<MenuBar contents={menuContents} />

			<img className={styles.image1} src={page1} />

			<div className={styles.commonBox1}>
				<div className={styles.notice}>
					※ 본 홈페이지의 CG, 그래픽 등은 소비자의 이해를 돕기 위한 이미지컷으로 실제와 다를 수 있습니다.
				</div>
				<div className={styles.notice}>
					※ 부대시설 일체의 운영 및 유지관리는 입주민이 자체적으로 해야하며, 커뮤니티 공간 내 운동기구, 옷장(락카)은 기본 제공되며, 작은 도서관, 골프연습장, 스크린골프, 어린이집, 경로당 등의 시설관리 및 운영을 위한 가구, 비품, 스탠드, 각종 집기류는 제공되지 않습니다.
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default ComplexGuide3;
